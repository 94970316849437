import React from 'react'
import './Cancel.css'
import { Redirect, useParams, useHistory  } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { parse } from "postcode";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';


//http://localhost:3000/jobId=fcc9470c-8dc1-46fe-b015-64643b868d6c&ref=873022

export const Cancel = () => {
    const [postcode, setPostcode] = React.useState('')
    const [display, setDisplay] = React.useState('main')
    const [postcodeError, setPostcodeError] = React.useState(false)
    const [redirectState, setRedirectState] = React.useState(false)

    const history = useHistory()

    const regEx = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    // // console.log(useParams()['id'])
    // if (regEx.test(useParams()['id']) &&){

    // }

    let { id,ref } = useParams();
    function updatePostcode(event){
        if (parse(event.target.value)['postcode']){
            setPostcode(parse(event.target.value)['postcode'])
        }
        else if (!parse(event.target.value)['postcode']){
            setPostcode(event.target.value)

        }
    }
    function submitPostcode(){
        // console.log(parse(postcode))
        if (!(regEx.test(id)) || !(/^\d+$/.test(ref)) || !(parse(postcode)['valid'])){
            setRedirectState(true)
        }
        //if postcode not valid i.e. postcode null then throw error. 
        setDisplay('loading')
        var payload = {
            "postcode":postcode,
            "id":id,
            "ref":ref
          }
          console.log('I have updated')
          console.log(payload)
            console.log('https://esurv.surveyhublive.net/customerbooking//?jobId='+id+'&ref='+ref)
          fetch('https://rebooking.esurv.co.uk/submit',
          {
          method: 'POST',
          headers : {
          'Content-Type':'application/json'},
          body: JSON.stringify(payload)}).then(function(response){
              if(response.status == '200'){
                //   console.log(response.status)
                  setDisplay('complete')
              }
              if(response.status == '400'){
                // console.log(response.status)

                setDisplay('400')
                
              }
              if(response.status == '404'){
                // console.log(response.status)

                setDisplay('404')
              }
              if(response.status == '403'){
                // console.log(response.status)
                setDisplay('400')
              }
          }
          )
    }
    function handleNotFound(){
        setDisplay('main')
    }
    if (redirectState){
        return <Redirect to='/'/>
    }
    const routeChange = () =>{ 
        // let path = `www.google.co.uk`; 
        // history.push(path);
        window.location.replace('https://esurv.surveyhublive.net/customerbooking//?jobId='+id+'&ref='+ref);
      }
  
    
    return (
            <Card className='box' variant='outlined'>
            {display==='main'&&
            <>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    ES-{ref}
                </Typography>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    So that we can rebook your appointment please verify the postcode for the  property being inspected​.
                </Typography>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    We will then cancel your original appointment and allow you to choose a new date and time for our visit.
                </Typography>
                <TextField id="standard-basic" label="Postcode" autoComplete='off' value={postcode} error={postcodeError} helperText={postcodeError?'Please enter a valid postcode to proceed with the cancellation':''} onChange={(event)=>updatePostcode(event)} variant="standard" fullWidth/>
                <Button fullWidth className='findButton' onClick={submitPostcode} disabled={postcode==''}variant="contained">Cancel Appointment</Button>
            </>}
            {display==='loading'&&
            <>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    ES-{ref}
                </Typography>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    Please wait while we cancel your appointment.
                </Typography>
                <CircularProgress className='circularProgress'/>
            </>}
            {display==='404'&&
            <>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    ES-{ref}
                </Typography>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    We were unable to locate a job for this postcode.
                </Typography>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    Please try again or contact the booking team: booking@esurv.co.uk or use the telephone number above.
                </Typography>
                <Button fullWidth className='findButton' onClick={handleNotFound} variant="contained">Try Again</Button>

            </>}
            {display==='400'&&
            <>
                {/* <Typography variant="subtitle2" className='cancelHelperText'>
                    ES-{ref}
                </Typography>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    We were unable to cancel your job at this time
                </Typography>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    Please try again or contact the booking team: booking@esurv.co.uk or use the telephone number above
                </Typography>
                <Button fullWidth className='findButton' onClick={handleNotFound} variant="contained">Try Again</Button> */}
                <div className='errorCard'>
                    <SentimentVeryDissatisfiedIcon sx={{width:'100%',fontSize:'100px',paddingTop:'1.5rem',color:'rgba(0,0,0,.54)'}}/>
                    <Typography variant='h4'className='notFoundText'>Sorry, there has been a problem; please call us to rebook your appointment.</Typography>
              </div>
            </>}
            {display==='complete'&&
            <>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    ES-{ref}
                </Typography>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    Your appointment has successfully been cancelled
                </Typography>
                <Typography variant="subtitle2" className='cancelHelperText'>
                    Please click below to be redirected to our booking website to select a new appointment. 
                </Typography>
                <Button fullWidth className='findButton' onClick={routeChange} variant="contained">Book New Appointment</Button>
            </>}
            </Card>
    

    )
}
